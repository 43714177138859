<template>
  <transition
    appear
    name="toast"
  >
    <div
      v-if="active"
      :class="[
        `overflow-hidden max-w-[70%]  shadow-xl fixed top-8 right-4 p-4 py-5 rounded-lg box  bg-white z-[900000000000000]`,
      ]"
    >
      <div
        :style="`width:${BORDER_WIDTH}%`"
        :class="[`${BG} h-1  absolute  bottom-0 left-0 transition-all duration-300`]"
      />
      <span @click="closeAlert()">
        <MaIcon
          name="add"
          class="absolute w-6 right-2 top-2 cursor-pointer text-black rotate-45"
        />
      </span>

      <div class="flex items-center pr-12 gap-3">
        <Icon
          :name="ICON"
          :class="[`${TEXT} w-10`]"
        />

        <div>
          <p class="text-semibold text-black">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { MaIcon } from '@wirepay/maplerad-component-lib'
import { useAlert } from '~/src/composables/core/useNotification'
const { active, message, closeAlert, type, ALERT_DURATION } = useAlert()

const BORDER_WIDTH = ref(100)

watch(active, (value) => {
  let intervalSequence: any

  if (value) {
    BORDER_WIDTH.value = 100
    intervalSequence = setInterval(() => {
      const substractedValue = (100 / (ALERT_DURATION / 75)).toFixed(1)
      BORDER_WIDTH.value -= substractedValue
    }, 75)

    setTimeout(() => {
      clearInterval(intervalSequence)
    }, ALERT_DURATION)
  }
})
const ICON = computed(() => {
  switch (type.value) {
    case 'ERROR':
      return 'info'
    case 'SUCCESS':
      return 'check'
    default:
      return 'info'
  }
})
const BG = computed(() => {
  switch (type.value) {
    case 'ERROR':
      return 'bg-red'
    case 'SUCCESS':
      return 'bg-green500'
    default:
      return 'bg-black'
  }
})
const TEXT = computed(() => {
  switch (type.value) {
    case 'ERROR':
      return 'text-red'
    case 'SUCCESS':
      return 'text-green500'
    default:
      return 'text-black'
  }
})
</script>

<style scoped>
/* enter transitions */
.toast-enter-active {
  animation: wobble 0.5s ease;
}

.toast-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.toast-leave-active {
  transition: all 0.3s ease;
}
@keyframes wobble {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  60% {
    transform: translateX(14px);
    opacity: 1;
  }
  70% {
    transform: translateX(-14px);
    opacity: 1;
  }
  80% {
    transform: translateX(7px);
    opacity: 1;
  }
  90% {
    transform: translateX(-7px);
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.box {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}
</style>
